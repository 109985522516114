import Style from "./Coming.module.scss";

export default function ComingSoon(){

    return(
        <div className={Style.comingSoon}>
            <video className={Style.bgVideo} muted controls={false} autoPlay loop src="newvide_petroleum.mp4"></video>
            <div className={Style.overlay}></div>
            <div className={Style.container}>
                <div className={Style.title}>
                    <img src="PetroleumBlast512-TR-nomarge.png"/>
                    <h1>Petroleum.land</h1>
                </div>
                <h2>The first Play2Earn on Blast is coming soon...</h2>
                <div className={Style.socials}>
                    {/* <a href="https://docs.petroleum.land" target="_blank">docs.petroleum.land</a> */}
                    <a href="https://twitter.com/Petroleum_Defi" target="_blank" title="Twitter">@Petroleum_Defi</a>
                    <a href="https://t.me/Petroleum_Defi" target="_blank" title="Twitter">@Petroleum_Defi</a>
                </div>
            </div>
        </div>
    )
}