import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import Home from "./Pages/Home/Home";
import "./Styles/App.scss";
import "./Styles/Animation.scss"
import SocialRow from "./Components/SocialRow/SocialRow";
import ComingSoon from "./Components/ComingSoon/ComingSoon";

function App() {
  return (
    <>
      {/* <div className="bg-top-left-gradient"></div> */}
      
      {/* <Navbar/>
      <SocialRow/> */}
      
      <Router>
        <Routes>
          <Route path="/" element={
            <>
              {/* <Home/> */}
              <ComingSoon />
            </>
          }/>
        </Routes>
      </Router>
    </>
  );
}

export default App;
